.app-ui {
  min-height: calc(100vh - 128px);

  .backdrop {
    z-index: 1400;
  }

  > .content {
    flex-grow: 1;
    padding-top: $spacing2 + 24px;
    padding-bottom: $spacing2;
    overflow-y: scroll;
    height: 82.5vh;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  .fullscreen {
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
  }

  .fullscreen-container {
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
  }

  .loading-bar {
    position: fixed;
    top: 65px;
    right: 0;
    width: calc(100vw - 42px);
  }
}

// Utilities
.mt-1 {
  margin-top: $spacing1 !important;
}
.mt-2 {
  margin-top: $spacing2 !important;
}
.mt-3 {
  margin-top: $spacing3 !important;
}
.mb-1 {
  margin-bottom: $spacing1 !important;
}
.mb-2 {
  margin-bottom: $spacing2 !important;
}
.mb-3 {
  margin-bottom: $spacing3 !important;
}
