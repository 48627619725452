.expand-more {
  display: none;
  &.root {
    display: flex;
    flex-direction: column;
  }

  .content {
    max-height: 0;
    overflow-y: hidden;
    transition: max-height 1s ease;
  }

  .expanded {
    max-height: 2500px !important;
  }
}
