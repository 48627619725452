.window-damage-star-page {
  .horizontal {
    width: 100%;
    display: flex;
    align-items: center;
    form {
      flex-grow: 1;
      padding-right: $spacing1;
    }
  }
}

.typography-star-plan {
  margin-top: $spacing4;
}

a.button-star {
  margin-top: $spacing2;
  margin-bottom: 0;
}

a.button-glass {
  margin-top: $spacing1;
  margin-bottom: $spacing1;
}
