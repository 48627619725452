.file-input {
  .button {
    background-color: $white;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    &:hover {
      background-color: $white;
    }
  }

  .input {
    display: none !important;
  }
  &.root {
    width: 100%;
    fieldset {
      width: 100%;
      height: 200px;
      padding: $spacing1;
      border-color: $divider-color;
      border-radius: $border-radius * 2;
      & legend {
        position: relative;
        top: -0.25rem;
        margin-bottom: -4px;
        padding-left: $spacing1;
        padding-right: $spacing1;
      }
    }
  }

  .title {
    color: $white;
  }

  .title-bar {
    background: 'linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%)';
  }

  .image {
    width: 100%;
    height: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  img {
    width: auto;
    height: 100%;
    position: absolute;
    top: calc(50% - 5px);
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: $border-radius;
    object-fit: cover;
  }

  .images {
    height: 170px;
    flex-wrap: nowrap;
    transform: translateZ(0);
    & li {
      height: 170px !important;
    }
    &li > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
