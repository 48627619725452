.clear-data-dialog {
  .dialog-buttons {
    margin-top: 30px;
    display: flex;
    width: 33%;
    .MuiButton-root {
      &.ok-button {
        color: $primary-dark;
        flex: 0.01;
      }

      &.cancel-button {
        color: $error-primary;
        flex: 0.01;
      }
    }

    .divider {
      flex: 1;
      width: 50%;
    }
  }
}
