.insurance-page {
  .button {
    margin-top: $spacing2;
  }

  .card {
    margin: $spacing3;
  }

  .countries {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    & td:first-child {
      color: $primary-dark;
    }
  }

  .expand-button {
    margin-top: $spacing2;
  }
}
