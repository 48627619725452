.error-view {
  .button {
    width: 80%;
  }

  .code {
    width: 80%;
    padding: $spacing1;
    background-color: $white;
    border-radius: $border-radius * 2;
  }

  .icon {
    color: $secondary;
    font-size: 10rem;
  }

  &.root {
    width: 100%;
    height: 100%;
    padding-top: $spacing2;
    padding-bottom: $spacing2;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .text {
    max-width: 80%;
    text-align: justify;
  }
}
