.contact-page {
  .nr {
    display: flex;
    align-items: center;
  }
}
.bkr-info {
  margin-top: 1rem;
  font-size: 0.875em;
  font-style: italic;
}
