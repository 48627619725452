.license-plate {
  &.root {
    height: 36px;
    display: flex;
  }

  * {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 18px;
    font-weight: 800;
  }

  :first-child {
    padding-left: $spacing1;
    padding-right: $spacing1;
    border-radius: $border-radius 0 0 $border-radius;
    background-color: $primary-dark;
    color: $white;
  }

  :last-child {
    min-width: 130px;
    border-radius: 0 $border-radius $border-radius 0;
    padding-left: $spacing1;
    padding-right: $spacing1;
    background-color: $secondary;
    color: $black;
    text-align: center;
  }
}
