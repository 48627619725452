.tire-location-form {
  .success-icon {
    color: $secondary;
    font-size: 12rem;
  }

  &.success-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  h6 {
    font-weight: 500;
    text-align: center;
    margin-bottom: $spacing2;
  }
}
