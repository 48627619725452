.drawer-menu {
  &.root {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .icon {
    width: 22px;
    height: 22px;
    filter: grayscale(100%) brightness(110.53%);
  }

  .refresh {
    padding: $spacing2;
    color: $grey;
    font-size: 0.8rem;
  }

  .scroll {
    width: 100%;
    height: 0;
    flex-grow: 1;
    flex-shrink: 1;
    overflow-x: auto;
  }

  .sub-header {
    line-height: initial;
    font-size: 0.8rem;
    padding-top: 16px;
    padding-bottom: 4px;
    background-color: $white;
  }
}
