.data-table {
  &.table {
    width: 100%;
    border-collapse: collapse;
    tr:nth-child(odd) {
      background-color: $white;
    }

    td {
      padding: $spacing1;
    }

    td:nth-child(2) {
      padding: $spacing1 $spacing1/2.0;
    }

    tr td:last-child {
      width: 100%;
    }

    tr td:last-child div {
      display: flex;
      align-items: center;
      & div {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        flex-grow: 1;
      }
    }

    button {
      margin-right: -$spacing1;
      &[data-info='warning'] {
        color: rgba(255, 0, 0, 0.54);
      }
    }

    div:first-child > span {
      color: $grey;
      font-size: 0.625rem;
      font-weight: 800;
      text-transform: uppercase;
    }
  }

  .no-separator {
    &td:nth-child(2) {
      display: none as none;
    }
  }
}
