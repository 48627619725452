.vehicle-summary {
  .title {
    padding-bottom: $spacing1;
    font-size: 1.15rem;
    font-weight: 500;
  }

  .wrapper {
    display: flex;
  }
}
