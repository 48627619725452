.insurance-not-contracted-page {
  .active {
    max-height: 100vh !important;
  }

  .expand {
    max-height: 0;
    overflow-y: hidden;
    transition: max-height 250ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .read-more-link {
    font-size: 0.75rem;
    text-decoration: none;
  }

  .hint {
    font-style: italic;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .title {
    font-size: 1.25rem;
  }
}
