.login-page,
.switch-user-page {
  .button {
    height: 56px;
    margin-top: $spacing1;
    font-weight: 800;
  }

  .MuiTypography-root a {
    color: $black;
  }

  .checkbox {
    width: 85%;
    color: $black;

    & svg {
      width: 0.9em;
      height: 0.9em;
      fill: $black;
    }

    &a {
      color: $black;
    }
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    & svg {
      width: 0.9em;
      height: 0.9em;
      fill: $primary-main;
    }
  }

  .container {
    width: 93%;
    height: 100%;
    max-width: 360px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .filler-top {
    height: 1.5rem;
  }

  .filler-bottom {
    height: 0;
    //flex-grow: 1;
  }

  .form {
    //width: 80%;
    background-color: $white;
    border-radius: $border-radius;
    padding: $spacing3;
  }

  .help-link {
    display: block;
    margin-top: $spacing2;
    font-size: 0.8rem;
    text-align: center;
    text-decoration: none;
  }

  .horizontal {
    display: flex;
    align-items: center;
  }

  .logo {
    height: 3rem;
    filter: invert(1) brightness(500);
  }

  .logo-wrapper {
    display: flex;
    align-items: center;
  }

  &.root {
    width: 100%;
    height: 100vh;
    padding-top: $spacing2;
    padding-bottom: $spacing2;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    background-image: url('../res/images/bhlba-login-bg-2x.jpg');
    background-position: center bottom;
    background-size: cover;
  }

  .switch {
    margin-right: $spacing1;
  }

  .text {
    color: $black;
  }

  input {
    border-color: $white;
    background-color: rgba(255, 255, 255, 0.05);

    &::-webkit-input-placeholder {
      color: $grey;
    }

    &:-ms-input-placeholder {
      color: $grey;
    }

    &::placeholder {
      color: $grey;
    }

    &:active {
      border-color: $white;
    }
  }

  .text-field-label {
    color: $grey;
  }

  .title {
    color: $black;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0;
  }

  p {
    &.small {
      font-size: 0.9rem;
      line-height: 1.25;
    }

    &.helper-text {
      margin: 0;
      line-height: 1rem;
    }

    &.error-message {
      color: $error-primary;
    }
  }

  .MuiFormHelperText-root {
    color: $dark-grey;
  }

  .MuiButton-containedSecondary:hover {
    background-color: #c79c00;
  }

  .MuiIconButton-root {
    position: relative;
    top: -0.5rem;
  }

  .MuiButton-root {
    font-size: 1rem;
    font-weight: 800;
    text-transform: none;
    box-shadow: none;
  }
}
