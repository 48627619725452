.tire-page,
.european-form-submit-page,
.damage-form-page {
  .actions {
    width: 100%;
    margin-top: $spacing2;
    margin-bottom: $spacing2;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.window-damage-page,
.insurance-not-contracted-page,
.window-damage-star-page,
.damage-page,
.damage-not-contracted-repair-page,
.damage-not-contracted-page,
.damage-not-contracted-esf-page {
  .button,
  button,
  a {
    margin-top: $spacing2;
    margin-bottom: $spacing2;
  }
}

.damage-page,
.damage-not-contracted-page {
  .title {
    margin-top: 1.75rem;
  }
}

.damage-not-contracted-repair-page {
  .horizontal {
    width: 100%;
    display: flex;
    align-items: center;

    & > form {
      flex-grow: 1;
      padding-right: $spacing1;
    }
  }
}

.tires-not-contracted-page,
.repair-page,
.window-damage-star-page,
.damage-not-contracted-repair-page {
  .location {
    border-radius: 2 * $border-radius;
    box-shadow: none;
    margin: 1rem 0 0 0 !important;

    &.Mui-expanded .MuiAccordionSummary-root {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.MuiAccordionSummary-content {
      width: calc(100% - 32px);
      overflow-x: hidden;
    }
    &:before {
      display: none;
    }
  }

  .loc-content {
    padding: $spacing2;
    padding-bottom: 0;
    margin-bottom: $spacing2;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-color: $white;
  }

  .loc-title {
    min-height: initial !important;
    padding-left: $spacing1;
    background-color: #d9d9d9;
    border-top-left-radius: 2 * $border-radius;
    border-top-right-radius: 2 * $border-radius;
    border-bottom-left-radius: 2 * $border-radius;
    border-bottom-right-radius: 2 * $border-radius;
    & .MuiAccordionSummary-content {
      margin: 0;
      display: flex;
      align-items: center;
      font-size: 1rem;
      font-weight: 600;
      white-space: nowrap;
    }

    & .MuiExpansionPanelSummary-content {
      margin: 0;
      padding: $spacing1;

      display: flex;
      align-items: center;

      & img {
        padding-right: $spacing1;
      }
      & h6 {
        margin-top: 0;
        font-size: 1rem;
        font-weight: 600;
        white-space: nowrap;
      }
      & p {
        font-size: 0.8rem;
      }
    }
  }

  .loc-address {
    font-size: 0.875rem;
  }
  .logo {
    height: 1.5rem;
    margin: $spacing1;
  }

  .title {
    font-size: 1.1rem;
    flex-grow: 1;
    margin-top: 0;
  }

  .title-wrapper {
    display: flex;
    align-items: center;
  }

  .content {
    display: block;
  }
  .snowflake {
    width: 1rem;
  }

  .warning {
    color: #ff0000;
    margin-top: 2rem;
  }
}

.full-width {
  width: 100%;
}

.hidden {
  display: none;
}

.success-icon {
  color: $secondary;
  font-size: 12rem;
}

.success-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  & h6 {
    font-weight: 500;
    text-align: center;
    margin-bottom: $spacing2;
  }
}

.background-primary {
  background-color: $primary-dark;
}
