.tire-page {
  .icon-big {
    height: 30px;
  }

  .icon-small {
    height: 24px;
    padding-top: 2px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    td {
      padding: 4px;
      padding-left: 8px;
      padding-right: 8px;
    }

    tr td:first-child {
      text-align: center;
    }

    tr td:last-child {
      width: 100%;
      vertical-align: center;
    }
  }

  .title {
    font-size: 1.1rem;
    flex-grow: 1;
    margin-top: 0rem;
  }

  .title-wrapper {
    display: flex;
    align-items: center;
  }

  .vehicle-summary .title {
    font-size: 1.15rem;
    margin-top: 1rem;
  }
}
