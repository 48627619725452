.bottom-bar {
  .icon {
    width: 22px;
    height: 22px;
    filter: grayscale(100%) brightness(110.53%);
  }

  .nav {
    width: 100%;
    height: 100%;
  }

  &.root {
    height: 64px;
    top: auto;
    bottom: 0;
    border-top: 2px solid $secondary;
    box-shadow: none;
  }
}
