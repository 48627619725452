.styled-list {
  &.root {
    padding-left: 0.25rem;
    list-style-type: none;
  }

  .item {
    position: relative;
    padding-left: 1.5em;
    margin-bottom: 0.857rem;
    &:before {
      content: '\2713';
      display: block;
      position: absolute;
      left: 0;
      top: 2px;
      color: $primary-main;
      font-weight: 600;
    }

    &ul {
      padding-left: 1rem;
      margin-top: 0.5rem;
      list-style-type: disc;
      &li {
        padding-left: 0;
        margin-bottom: 0.25em;
        &:before {
          display: none;
        }
      }
    }
  }
}
