.insurance-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: $secondary;
  border-radius: $border-radius;
  .actions {
    flex-basis: 50%;
    flex-grow: 2;
    & > :first-child {
      margin-bottom: $spacing1;
    }
  }

  .button,
  .button-black {
    justify-content: flex-start;
  }

  .button-black {
    background-color: $black;
    color: $white;
  }

  .polis-icon {
    flex-grow: 1;
    margin-right: $spacing1;
    filter: grayscale(100%) brightness(23.18%);
  }

  & > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: $spacing2;
    padding-top: 0;
  }

  .text {
    font-size: 1rem;
    font-weight: 800;
    margin: $spacing1;
  }
}
