.terms-dialog {
  .text {
    flex-shrink: 1;
  }

  .title {
    overflow-y: initial;
  }

  .header {
    overflow-y: initial;
  }
}
