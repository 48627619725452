.app-bar {
  background-color: $white !important;
  border-bottom: 1px solid #cacaca !important;
  box-shadow: none !important;
  color: $primary-dark !important;
  &::before {
    width: 0px;
    height: 0px;
    content: ' ';
    position: absolute;
    top: 100%;
    left: 40px;
    border: solid transparent;
    border-color: rgba(255, 0, 0, 0);
    border-top-color: #cacaca;
    border-width: 25px;
    margin-left: -25px;
    clip: rect(1px, 100px, 100px, 0px);
    transform: scale(1.75, 1) translateY(-1px);
    pointer-events: none;
  }
  &::after {
    width: 0px;
    height: 0px;
    content: ' ';
    position: absolute;
    top: 100%;
    left: 40px;
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #fff;
    border-width: 24px;
    margin-left: -24px;
    clip: rect(0px, 100px, 100px, 0px);
    transform: scale(1.75, 1) translateY(-1px);
    pointer-events: none;
  }

  .icon {
    font-size: 2.5rem;
  }

  .icon-wrapper {
    width: 84px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 0;
  }

  .logo {
    height: 60%;
    margin-right: $spacing1;
  }

  .logo-title {
    font-size: 1.125rem;
  }

  .logo-wrapper {
    flex-grow: 1;
  }

  .menu-button {
    position: relative;
    margin-left: 0;
  }

  .title {
    position: absolute;
    top: 50%;
    //left: 84;
    //same-as-icon-wrapper-right: 50vw;
    margin-top: 0;
    font-size: 1.125rem;
    transform: translate(50%, -50%);
    right: 50vw;
  }

  .title-wrapper {
    height: 100%;
    position: absolute;
    top: 50%;
    right: 50vw;
    display: flex;
    align-items: center;
    flex-direction: row;
    transform: translate(50%, -50%);
  }

  .toolbar {
    height: 64px;
    padding: 0px;
  }
}
