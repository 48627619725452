.login-faq-dialog,
.clear-data-dialog {
  .container {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 40px;
    padding-bottom: 16px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  &.root {
    .MuiDialog-container .MuiPaper-root {
      background-color: #f2f2f2;
    }
  }
}
