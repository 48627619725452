.no-content {
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow-y: scroll;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url('../res/images/bhlba-login-bg-2x.jpg');
  background-position: center bottom;
  padding: 0;
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  .MuiTypography-body1 {
    color: white;
    text-align: center;
    padding: 15px;
  }
}
