$black: #0a0a0a; // theme.palette.common.black
$grey: #9e9e9e; // theme.palette.grey[500]
$dark-grey: #262626; // theme.palette.grey[500]
$primary-dark: #0673b0; // theme.palette.primary.dark
$primary-main: #00a7d6; // theme.palette.primary.main
$white: #fefefe; // theme.palette.common.white
$secondary: #ffcc00; // theme.palette.secondary.main
$divider-color: rgba(0, 0, 0, 0.12); // theme.palette.divider
$spacing1: 8px; // theme.spacing(1)
$spacing2: 16px; // theme.spacing(2)
$spacing3: 32px; // theme.spacing(3) ~
$spacing4: 40px; // theme.spacing(4) ~
$spacing5: 60px; // theme.spacing(5) ~
$drawer-width: 240px; // drawerWidth
$border-radius: 3px; // theme.shape.borderRadius
$error-primary: #cc0000;
