.window-damage-broken-page {
  .typography-damage {
    margin-top: $spacing1;
  }

  .typography-damage-info {
    margin-bottom: $spacing3;
  }

  .typography-damage-plan {
    margin-top: $spacing3;
  }

  .typography-damage-buurt {
    margin-top: $spacing5;
    margin-bottom: $spacing1;
  }

  .typography-damage-verbijzelde {
    margin-top: $spacing5;
    margin-bottom: $spacing1;
  }

  .typography-damage-tijdens {
    margin-top: $spacing1;
  }

  .typography-damage-buiten {
    margin-top: $spacing1;
  }

  .typography-damage-vervanging {
    margin-top: $spacing4;
    margin-bottom: $spacing5;
  }
}
