.overview {
  &.root {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .card {
    display: flex;
    padding: $spacing1;
    margin-bottom: $spacing2;
    background-color: $white;
    border-radius: $border-radius;
    & > :last-child {
      padding: $spacing1;
      flex-grow: 1;
    }
  }

  .icon {
    padding: $spacing1;
    margin-left: $spacing1;
    margin-right: $spacing1;
    display: flex;
    align-items: center;
    color: $primary-dark;
    svg {
      width: 2em;
      height: 2em;
    }
  }

  .title {
    display: flex;
    align-items: center;
    margin-bottom: $spacing1;
    & > * {
      width: fit-content;
    }
    & > :first-child {
      flex-grow: 1;
      font-size: 1.15rem;
    }
  }
}
