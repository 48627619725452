.styled-table {
  &.root {
    margin-top: $spacing2;
    margin-bottom: $spacing2;
    border: 0px none;
    border-spacing: 0;
    tr:first-child td {
      border-bottom: 2px solid black;
      font-style: initial;
    }

    tr td {
      padding: $spacing1;
      font-style: italic;
    }
  }
}
