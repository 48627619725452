.app-drawer {
  &.drawer {
    width: $drawer-width;
  }

  .drawer-paper {
    width: $drawer-width;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .drawer-open {
    width: $drawer-width;
  }

  .header {
    width: 100%;
    height: 64px;
    flex-basis: 64px;
    min-height: 64px;
  }
}
