.insurance-document-page {
  &.frame {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
    .react-transform-component {
      width: 100%;
      height: 100%;
      flex-shrink: 1;
    }

    .react-transform-element {
      align-items: flex-start;
    }

    .image {
      width: 100%;
      object-fit: contain;
    }
  }
}
