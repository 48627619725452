.delivery-page {
  .active {
    max-height: 2500px !important;
  }

  .expand {
    max-height: 0;
    overflow-y: hidden;
    transition: max-height 1s ease;
  }

  .info {
    margin-top: -4;
    margin-left: $spacing1;
    padding: 0;
    font-size: 1.15rem;
  }

  .subtitle {
    color: $grey;
    font-size: 0.625rem;
    font-weight: 800;
  }

  .title {
    font-size: 1.15rem;
  }
}
