.icon-button {
  .icon {
    height: 48px;
    padding-bottom: $spacing1;
  }

  .label {
    flex-direction: column;
    font-size: 1rem;
    line-height: normal;
    text-align: center;
    text-transform: initial;
    span {
      margin-right: 0;
    }
  }

  .label-text {
    height: 38px;
    display: flex;
    align-items: center;
  }

  &.root {
    width: calc(100% - 2 * 16px);
    margin: $spacing2;
    padding-bottom: $spacing2;
    padding-left: $spacing1;
    padding-right: $spacing1;
    padding-top: $spacing2;
    background-color: $white;
    border-radius: 18px;
    box-shadow: none;
    &:hover {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
      background-color: #d5d5d5;
    }
    &:active {
      box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    }
  }
}
