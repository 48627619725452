.document-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.85);
  .typography {
    color: white;
    margin-top: 16px;
    font-size: 1.2rem;
  }
}
