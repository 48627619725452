.loading-view {
  &.root {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../res/images/bhlba-login-bg-2x.jpg');
    background-position: center;
    background-size: cover;
  }
}
