.drawer-header {
  .logo {
    height: 2rem;
  }

  &.logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title {
    color: $black;
    margin-left: $spacing1;
    font-size: 1rem;
    font-weight: 900;
    text-transform: uppercase;
    &:last-child {
      margin-left: 0.25rem;
      color: $primary-dark;
    }
  }
}
