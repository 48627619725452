.info-page {
  &.root {
    & > .MuiDialog-container > .MuiPaper-root {
      background-color: #f2f2f2;
    }

    .container {
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 40px;
      padding-bottom: 16px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }
  }

  .escaped-double-dots-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .content {
    display: block;
  }

  .snowflake {
    width: 1rem;
  }
}
